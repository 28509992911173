function loadImage(src){
	return new Promise((r,e)=>{
		var img = new Image();
		img.onload = ()=>r(img);
		img.onerror = e;
		img.src = src;
	});
}

export async function scaleAndFitImage(src, w, h) {
	var img = await loadImage(src);
	var canvas = document.createElement('canvas');
	canvas.width = w; canvas.height = h;
	var ctx = canvas.getContext('2d');
	var scale = Math.min(canvas.width / img.width, canvas.height / img.height);
	var x = (canvas.width / 2) - (img.width / 2) * scale;
	var y = (canvas.height / 2) - (img.height / 2) * scale;
	ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
	return canvas.toDataURL();
}

export async function scaleAndCoverImage(src, w, h) {
  var img = await loadImage(src);
	var canvas = document.createElement('canvas');
	canvas.width = w; canvas.height = h;
	var ctx = canvas.getContext('2d');
	var x = 0;
	var y = 0;
	var offsetX = 0.5;
	var offsetY = 0.5;
	var iw = img.width;
	var ih = img.height;
	var r = Math.min(w / iw, h / ih);
	var nw = iw * r;   // new prop. width
	var nh = ih * r;   // new prop. height
	var cx;
	var cy;
	var cw;
	var ch;
	var ar = 1; 
	if (nw < w) ar = w / nw;                             
	if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;  // updated
	nw *= ar;
	nh *= ar;
	cw = iw / (nw / w);
	ch = ih / (nh / h);
	cx = (iw - cw) * offsetX;
	cy = (ih - ch) * offsetY;
	if (cx < 0) cx = 0;
	if (cy < 0) cy = 0;
	if (cw > iw) cw = iw;
	if (ch > ih) ch = ih;
	ctx.drawImage(img, cx, cy, cw, ch,  x, y, w, h);
	return canvas.toDataURL();
}