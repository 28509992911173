/**
 * Code.jsx
 * Page for posts about Code
 */

import { PostsSummary } from '#components/PostsSummary';


export function Code(){
	return (<>
		<PostsSummary noResultsText='No code posts yet' tags={['code']} />
	</>);
}