/**
 * Coffee.jsx
 * Page for posts about coffee
 */

import { PostsSummary } from '#components/PostsSummary';


export function Coffee(){
	return (<>
		<PostsSummary noResultsText='No coffee posts yet' tags={['coffee']} />
	</>);
}