/**
 * Home.jsx
 * The initial landing page
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faGithub, faInstagram, faLinkedinIn} from "@fortawesome/free-brands-svg-icons";

import {useNavHelper} from '#hooks/useNavHelper';
import config from '#config/server';
import { PostsSummary } from '#components/PostsSummary';


export function Home(){
	const navigate = useNavHelper();

	return (<>
		<section className="resume-section">
			<div className="resume-section-content">

				<div className="row flex-lg-row-reverse align-items-center g-5 py-5">
					<div className="col-sm-12 col-lg-6">
						<img src={`${config.base_url}assets/images/hero.gif`} style={{borderRadius:'1em'}} className="d-block mx-lg-auto img-fluid" />
					</div>
					<div className="col-lg-6">
						<h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3 text-white">I'm <span className="text-secondary">Robert</span>,</h1>
						<p className="lead text-white">adventurer, creator, connoisseur. This is where I share my thoughts. If you're looking, <a href='#' onClick={e=>navigate(e, '/resume')}>my resume is here</a>.</p>

						<div className="social-icons text-white">
							<a className="social-icon" href="https://www.linkedin.com/in/robert-parham/" target="_blank" title="Linked In">
								<FontAwesomeIcon icon={faLinkedinIn} />
							</a>
							<a className="social-icon" href="https://github.com/Pamblam" target="_blank" title="Github">
								<FontAwesomeIcon icon={faGithub} />
							</a>
							<a className="social-icon" href="https://www.instagram.com/rob_does_helth/" target="_blank" title="Instagram">
								<FontAwesomeIcon icon={faInstagram} />
							</a>
						</div>
					</div>
				</div>
			</div>
		</section>
		
		<section className="resume-section">
			<div className="resume-section-content">
				<PostsSummary />
			</div>
		</section>
		
	</>);
}