export function Resume(){
	return (<>
		<div style={{ maxWidth: 816, margin: "0 auto" }}>
		  <h1
			className="name"
			style={{
			  textAlign: "center",
			  fontFamily: "Arial",
			  fontSize: "20pt",
			  textDecoration: "none !important",
			  fontWeight: "bold"
			}}
		  >
			ROBERT PARHAM
		  </h1>
		</div>
		<div
		  style={{
			fontFamily: "Arial",
			fontSize: "12pt",
			fontWeight: "bold",
			textAlign: "center"
		  }}
		>
		  <a
			style={{ color: "inherit", textDecoration: "none" }}
			href="mailto:adelphia@protonmail.ch"
		  >
			adelphia@protonmail.ch
		  </a>{" "}
		  | Tampa Bay, FL |{" "}
		  <a
			style={{ color: "inherit", textDecoration: "none" }}
			href="tel:8136160819"
		  >
			(813) 616-0819
		  </a>
		</div>
		<div
		  style={{
			fontFamily: "Arial",
			fontSize: "12pt",
			fontWeight: "bold",
			textAlign: "center"
		  }}
		>
		  <a
			target="_blank"
			style={{ color: "inherit", textDecoration: "none" }}
			href="https://linkedin.com/in/robert-parham"
		  >
			linkedin.com/in/robert-parham
		  </a>{" "}
		  |{" "}
		  <a
			target="_blank"
			style={{ color: "inherit", textDecoration: "none" }}
			href="http://robert-parham.com"
		  >
			robert-parham.com
		  </a>{" "}
		  |{" "}
		  <a
			target="_blank"
			style={{ color: "inherit", textDecoration: "none" }}
			href="https://github.com/pamblam"
		  >
			github.com/pamblam
		  </a>
		</div>
		<hr />
		<p>
		  An experienced Software Engineer and Front-End Developer with a extensive
		  background creating an enhanced End-User Experience across web and mobile
		  digital touch points. Heightened communication skills and advanced technical
		  capabilities in a variety of environments support an ability to manage
		  resources, determine end-user requirements and collaborate in team-centric,
		  outcome-focused efforts. Progressive programming and coding abilities
		  deploying a wide range of platforms complement client-focused design
		  capabilities; elevating and energizing seamless and responsive usability.
		</p>
		<h2
		  style={{
			margin: 0,
			textAlign: "center !important",
			fontFamily: "Arial",
			fontWeight: "bold",
			fontSize: "12pt",
			writingMode: "horizontal-tb",
			direction: "ltr"
		  }}
		>
		  VALUE ADDED SKILLS
		</h2>
		<p style={{ textAlign: "center" }}>
		  Full Stack Design | Project Management | UI / UX | Web Design | Mobile App
		  Development | UAT | Software Development | User Requirements Analysis |
		  Quality Assurance | Stakeholder Relationship Management | Iterative
		  Prototypes | Server Administration | Troubleshooting | Infrastructure Design
		  | Web Design | Client Relationship Management | Local Area Networks |
		  Product Design and Development
		</p>
		<br />
		<hr />
		<h2
		  style={{
			fontFamily: "Arial",
			fontSize: "12pt",
			fontWeight: "bold"
		  }}
		>
		  PROFESSIONAL EXPERIENCE
		</h2>
		<br />
		<div style={{ breakInside: "avoid" }}>
		  <h3
			style={{
			  fontFamily: "Arial",
			  fontWeight: "bold",
			  fontSize: "11pt"
			}}
		  >
			MINIBEAST ENTERPRISES
		  </h3>
		  <h3
			style={{
			  fontFamily: "Arial",
			  fontWeight: "bold",
			  fontSize: "11pt"
			}}
		  >
			Orlando, FL | Full Stack Programmer | October 2021 – Present
		  </h3>
		  <p>
			Profiled by Fox News, CBS, USA Today and Cosmopolitan magazine, a holistic
			health and wellness enterprise where I successfully:
		  </p>
		  <ul>
			<li>
			  Innovated a hybrid mobile app and web app, using Flutter, Cordova,
			  ReactJS, PHP, MySQL and Bash.
			</li>
			<li>
			  Manage and program the Linux and Apache server hosted on Digital Ocean
			  while maintaining the Postmark email service via APIs.
			</li>
			<li>Structure the S3 content management system using Digital Ocean.</li>
			<li>Program and schedule automate backups and maintenance tasks.</li>
			<li>
			  Designed and iterated app-based social groups modeled after Facebook
			  including a body fat percentage calculator and a guided workout function
			  with demo videos.
			</li>
		  </ul>
		</div>
		<br />
		<div style={{ breakInside: "avoid" }}>
		  <h3
			style={{
			  fontFamily: "Arial",
			  fontWeight: "bold",
			  fontSize: "11pt"
			}}
		  >
			OUR TOWN AMERICA
		  </h3>
		  <h3
			style={{
			  fontFamily: "Arial",
			  fontWeight: "bold",
			  fontSize: "11pt"
			}}
		  >
			Clearwater, FL | Full Stack Programmer | June 2015 – Present
		  </h3>
		  <p>
			Part of New Mover Direct Mail Marketing, a leading customer loyalty and
			digital marketing agency. Driving product development focusing on the user
			experience, I am responsible for:
		  </p>
		  <ul>
			<li>
			  Using LLMs to create functional AI assistant with ability to assist with
			  internal company tasks.
			</li>
			<li>
			  Developing client User Stories to design and create iterative UI/UX
			  prototypes.
			</li>
			<li>Translating high level technical features into prototype designs.</li>
			<li>
			  Maintaining three distinct client-facing, internal and internal hybrid
			  mobile web apps using Cordova.
			</li>
			<li>
			  Creating mapping software to visualizes various demographic and various
			  other data at Zip Code level.
			</li>
			<li>
			  Preparing wireframes, storyboards, process flows and site maps and
			  conduct ongoing user testing to support functionality and usability.
			</li>
			<li>Managing ongoing experience, A/B, Functionality and Unit Testing.</li>
			<li>
			  Building and performing updates to the company’s primary website and
			  landing pages.
			</li>
			<li>
			  Generating a CRM software which utilizes Twilio API for telephone
			  communications and HelloSign API to send documents for signing by leads
			  and clients.
			</li>
		  </ul>
		</div>
		<br />
		<div style={{ breakInside: "avoid" }}>
		  <h3
			style={{
			  fontFamily: "Arial",
			  fontWeight: "bold",
			  fontSize: "11pt"
			}}
		  >
			CARNET MARKETING &amp; CUSTOMER TRACK
		  </h3>
		  <h3
			style={{
			  fontFamily: "Arial",
			  fontWeight: "bold",
			  fontSize: "11pt"
			}}
		  >
			Safety Harbor, FL | Product Manager | June 2010 – June 2015
		  </h3>
		  <p>
			With responsibility for conceptualizing and launching business development
			and lead generation platforms for a US-wide network of car dealerships, I:
		  </p>
		  <ul>
			<li>
			  Designed, tested, and launched company’s proprietary enterprise CRM
			  deploying an advanced knowledge of, and abilities in PHP and MySQL
			</li>
			<li>
			  Wrote the code for the company’s Call Center’s dialer software using
			  Twilio, PHP, JavaScript, and MySQL
			</li>
			<li>
			  Built and performed regular updates for the company’s physical Linux and
			  Apache servers.
			</li>
		  </ul>
		</div>
		<br />
		<h2
		  style={{
			fontFamily: "Arial",
			fontWeight: "bold",
			fontSize: "12pt"
		  }}
		>
		  COMPLEMENTARY EXPERIENCE
		</h2>
		<br />
		<div style={{ breakInside: "avoid" }}>
		  <h3
			style={{
			  fontFamily: "Arial",
			  fontWeight: "bold",
			  fontSize: "11pt"
			}}
		  >
			WESTBAY SOFTWARE LLC
		  </h3>
		  <h3
			style={{
			  fontFamily: "Arial",
			  fontWeight: "bold",
			  fontSize: "11pt"
			}}
		  >
			Tampa Bay, FL | Owner / Developer | January 2005 – Present
		  </h3>
		  <p>
			A self-directed initiative where working with brands including Elite
			Marketing, Extreme Bio Clean-up, and Monster Flea Market, I am responsible
			for:
		  </p>
		  <ul>
			<li>
			  Creating hybrid mobile apps using PHP, Twilio, Cordova, PHP, Javascript
			</li>
			<li>
			  Designing a sales and marketing app that enables companies to
			  incentivize their customers to refer other customers by offering them
			  cash for verified referrals.
			</li>
			<li>Writing and maintaining Call Routing software via Twilio</li>
			<li>Providing extensive IT maintenance and troubleshooting support.</li>
		  </ul>
		</div>
		<br />
		<hr />
		<h2
		  style={{
			fontFamily: "Arial",
			fontSize: "12pt",
			fontWeight: "bold"
		  }}
		>
		  EDUCATION AND CERTIFICATIONS
		</h2>
		<br />
		<p>Bachelor of Science in Computer Science (incomplete)</p>
		<p>St. Petersburg College | Tarpon Springs, FL</p>
		<ul>
		  <li>React + Redux</li>
		  <li>AWS Cloud Practitioner</li>
		  <li>AWS Developer Associate</li>
		  <li>AWS Solutions Architect</li>
		  <li>AWS Sysops Administrator Associate</li>
		</ul>
		<br />
		<hr />
		<h2
		  style={{
			fontFamily: "Arial",
			fontSize: "12pt",
			fontWeight: "bold"
		  }}
		>
		  TECHNICAL SKILLSETS
		</h2>
		<br />
		<p>
		  MS Office Suite | JavaScript | PHP | Apache | SQL Linux | Twilio | WordPress
		  | HTML5 Bootstrap | Cordova | Flutter | Dart | Python | ReactJS | Bash | VS
		  Code | My SQL Workbench | Libre Office | VS Code | Mac | Linux | Vector
		  Database | LLM | AI
		</p>
		<br />
		<hr />
		<h2
		  style={{
			fontFamily: "Arial",
			fontSize: "12pt",
			fontWeight: "bold"
		  }}
		>
		  AFFILIATIONS
		</h2>
		<br />
		<p>
		  Member in Good Standing of the Free and Accepted Masons of Florida,
		  supporting a number of local charitable and community development
		  organizations.
		</p>
		<br />
		<hr />
		<h2
		  style={{
			fontFamily: "Arial",
			fontSize: "12pt",
			fontWeight: "bold"
		  }}
		>
		  PROJECTS
		</h2>
		<br />
		<p>A tiny sample of some of my personal projects.</p>
		<ul>
		  <li>
			<b>
			  <a href="https://github.com/Pamblam/jSQL">jSQL</a>
			</b>{" "}
			- An entire SQL engine written from scratch designed to save data on the
			client side.
		  </li>
		  <li>
			<b>
			  <a href="https://pamblam.github.io/animate.js/">Animate.js</a>
			</b>{" "}
			- A tiny, efficient way to animate anything.
		  </li>
		  <li>
			<b>
			  <a href="https://github.com/Pamblam/finder-clone">Finder Clone</a>
			</b>{" "}
			- A file system clone, modeled after Mac's Finder.
		  </li>
		  <li>
			<b>
			  <a href="https://moodsense.dev/">Moodsense</a>
			</b>{" "}
			- One of the winners of the TadHacks 2024 Hackathon, an AI therapist tool.
		  </li>
		  <li>
			<b>
			  <a href="https://pamblam.github.io/canvas-layers/examples/basic_example.html">
				Canvas Layers
			  </a>
			</b>{" "}
			- A tool for creating user-editable graphics in canvas.
		  </li>
		</ul>
	  </>);
}