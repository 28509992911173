/**
 * PostsSummary.jsx
 * Shows a paginated list of post summaries.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight, faAnglesLeft, faGlasses } from '@fortawesome/free-solid-svg-icons';
import {useState, useCallback, useEffect} from 'react';

import {useNavHelper} from '#hooks/useNavHelper';
import { scaleAndCoverImage } from '#modules/scaleAndCoverImage';
import { APIRequest } from '#modules/APIRequest';
import config from '#config/server';

export function PostsSummary({searchQuery='', noResultsText='', tags=[]}){

	const navigate = useNavHelper();
	let [results, setResults] = useState([]);
	let [page, setPage] = useState(1);
	let [totalPages, setTotalPages] = useState(0);

	const generateThumb = useCallback(node=>{
		if(node){
			scaleAndCoverImage(node.dataset.src, 600, 600).then(src=>{
				node.src = src;
			});
		}
	});

	const getRows = async ()=>{
		let res;
		if(tags.length){
			res = await new APIRequest('Pagination').get({query:'all_posts_of_tag', params: JSON.stringify({tags}), page, order_by_col:'create_ts', order_dir:'desc', page_size:15, search_term:searchQuery});
		}else{
			res = await new APIRequest('Pagination').get({query:'all_posts_summary', page, order_by_col:'create_ts', order_dir:'desc', page_size:15, search_term:searchQuery});
		}
		if(!res.has_error){
			setTotalPages(res.data.total_pages);
			setResults(res.data.results);
		}
	};

	useEffect(()=>{
		getRows();
	}, [page, searchQuery]);

	let pagination = '';
	if(totalPages > 1){
		let p_btns = [];
		for(let i=0; i<totalPages; i++){
			let curr_page = i+1;
			// Draw the first page, if the current page isn't the first page
			if(curr_page == 1 && page !== 1){
				p_btns.push(<li key={`page-btn-${curr_page}`} className="page-item"><a className="page-link" href="#" onClick={e=>{e.preventDefault(); setPage(1);}}><FontAwesomeIcon icon={faAnglesLeft} /></a></li>);
			}
			// draw the previous button if the current page is the one precending the page, but obnly if the last page isn't the first page
			if(curr_page == page - 1 && page > 2){
				p_btns.push(<li key={`page-btn-${curr_page}`} className="page-item"><a className="page-link" href="#" onClick={e=>{e.preventDefault(); setPage(curr_page);}}>{curr_page}</a></li>);
			}
			// draw the current page
			if(curr_page == page){
				p_btns.push(<li key={`page-btn-${curr_page}`} className="page-item"><a className="page-link disabled" href="#" onClick={e=>{e.preventDefault();}}>{curr_page}</a></li>);
			}
			// draw the next page, but only if it's not the last page
			if(curr_page == page + 1 && curr_page < totalPages){
				p_btns.push(<li key={`page-btn-${curr_page}`} className="page-item"><a className="page-link" href="#" onClick={e=>{e.preventDefault(); setPage(curr_page);}}>{curr_page}</a></li>);
			}
			// Draw the last page, if the current page is not the last page
			if(curr_page == totalPages && page !== totalPages){
				p_btns.push(<li key={`page-btn-${curr_page}`} className="page-item"><a className="page-link" href="#" onClick={e=>{e.preventDefault(); setPage(curr_page);}}><FontAwesomeIcon icon={faAnglesRight} /></a></li>);
			}
		}

		pagination = (<div className='clearfix'>
			<nav className='float-end'>
				<ul className="pagination">
					{p_btns}
				</ul>
			</nav>
		</div>);
	}

	return (<>
		{results.map((post, idx)=>{
			if(post.graph_img){

				let graphic = (<div className="col-sm-12 col-lg-6">
					<img onClick={e=>navigate(e, `/post/${post.slug}`)} ref={generateThumb} src={`${config.base_url}assets/images/loading-bar-animated-gif-transparent-background-3.gif`} data-src={post.graph_img} style={{borderRadius:'1em', cursor:'pointer'}} className="d-block mx-auto img-fluid thumb-img" />
				</div>);

				let summary = (<div className={`col-lg-6 ${idx % 2 === 1 ? '' : 'text-end-lg'}`}>
					<h1 onClick={e=>navigate(e, `/post/${post.slug}`)} className="display-5 fw-bold text-body-emphasis lh-1 mb-3 text-secondary" style={{cursor:'pointer'}}>{post.title}</h1>
					<small style={{fontSize:'0.8em'}}>by {post.author_name} on {new Date(post.create_ts * 1000).toLocaleDateString()}</small>
					{!!post.summary && (<p className='lead text-white'>{post.summary}</p>)}
				</div>);

				if(idx % 2 === 1){
					return (<div key={post.id} className="row flex-lg-row-reverse align-items-center g-5 py-5">
						{graphic}
						{summary}
					</div>);
				}else{
					return (<div key={post.id} className="row align-items-center g-5 py-5">
						{graphic}
						{summary}
					</div>);
				}
			}else{
				return (<div className="px-4 py-5 text-center">
					<div className="py-5">
					<h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3 text-secondary">{post.title}</h1>
						<div className="col-lg-6 mx-auto">
							<small style={{fontSize:'0.8em'}}>by {post.author_name} on {new Date(post.create_ts * 1000).toLocaleDateString()}</small>
							{!!post.summary && (<p className='lead text-white'>{post.summary}</p>)}
							<div><button className='btn btn-primary' onClick={e=>navigate(e, `/post/${post.slug}`)}><FontAwesomeIcon icon={faGlasses} /> Read the post</button></div>
						</div>
					</div>
				</div>);
			}
		})}
		{results.length === 0 && !!noResultsText && (<p>{noResultsText}</p>)}
		{pagination}
		<br/>
	</>);
}