export function Resume(){
	return <iframe
		src="assets/resume2.pdf"
		style={{
		width: '100%',
		height: '100vh',
		border: 'none',
		backgroundColor: 'transparent'
		}}
		scrolling="no"
		frameBorder="0"
		allowTransparency="true"
	/>;
}