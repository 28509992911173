/**
 * Navbar.jsx
 * The navigation bar at the top of the page, with branding.
 */

import {useRef, useCallback, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSplotch, faMagnifyingGlass, faMugHot, faCode } from '@fortawesome/free-solid-svg-icons';

import {useNavHelper} from '#hooks/useNavHelper';
import { animate } from '#modules/aminmate';
import config from '#config/server';

export function Navbar(){
	const navigate = useNavHelper();
	let inputRef = useRef();
	let iconRef = useRef();
	let iconRef2 = useRef();

	let setIconRef = useCallback(node=>{
		if(node){
			iconRef.current = node;
		}
	});

	let setIconRef2 = useCallback(node=>{
		if(node){
			iconRef2.current = node;
		}
	});

	let setInputRef = useCallback(node=>{
		if(node){
			inputRef.current = node;
		}
	});

	useEffect(()=>{
		let timer = setInterval(async ()=>{
			if(iconRef.current){
				await animate({ 
					element: iconRef.current, 
					style_property: 'transform', 
					start_value: 0, 
					end_value: 30, 
					unit: p => `rotate(${p}deg)`, 
					duration: 1000, 
					algo: 'easeInOutCubic' 
				});
				await animate({ 
					element: iconRef.current, 
					style_property: 'transform', 
					start_value: 30, 
					end_value: 0, 
					unit: p => `rotate(${p}deg)`, 
					duration: 1000, 
					algo: 'easeInOutCubic' 
				});
			}
			
		}, 2000);
		return ()=>{
			clearInterval(timer);
		};
		
	}, [setIconRef]);

	useEffect(()=>{
		let timer = setInterval(async ()=>{
			if(iconRef2.current){
				await animate({ 
					element: iconRef2.current, 
					style_property: 'transform', 
					start_value: 0, 
					end_value: 30, 
					unit: p => `rotate(${p}deg)`, 
					duration: 1000, 
					algo: 'easeInOutCubic' 
				});
				await animate({ 
					element: iconRef2.current, 
					style_property: 'transform', 
					start_value: 30, 
					end_value: 0, 
					unit: p => `rotate(${p}deg)`, 
					duration: 1000, 
					algo: 'easeInOutCubic' 
				});
			}
			
		}, 2000);
		return ()=>{
			clearInterval(timer);
		};
		
	}, [setIconRef2]);

	return (<nav className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top" id="sideNav">
		<a className="navbar-brand" href="#" onClick={e=>nav(e, '/')}>
			<a href='#' onClick={e=>navigate(e, '/')} className="d-block d-lg-none text-secondary" style={{textDecoration:'none'}}><FontAwesomeIcon icon={faSplotch} ref={setIconRef2} /> Rob Parham</a>
			<span className="d-none d-lg-block">
				<img className="img-fluid img-profile rounded-circle mx-auto mb-2" src={`${config.base_url}assets/images/profile.png`} alt="Robert Parham"/>
			</span>
		</a>
		<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
			<span className="navbar-toggler-icon" />
		</button>
		<div className="collapse navbar-collapse" id="navbarResponsive">
			<ul className="navbar-nav">
				<li className="nav-item">
					<form className="d-flex m-3" role="search" onSubmit={e=>navigate(e, '/search/'+encodeURIComponent(inputRef.current.value.trim()))}>
						<div className="input-group">
							<input type="search" className="form-control" placeholder="Search" ref={setInputRef} />
							<button className="btn btn-secondary" type="submit">
								<FontAwesomeIcon icon={faMagnifyingGlass} />
							</button>
						</div>
					</form>
				</li>
				<li className="nav-item">
					<a className="nav-link" href="#" onClick={e=>navigate(e, '/')}><FontAwesomeIcon icon={faSplotch} ref={setIconRef} /> Rob Parham</a>
				</li>
				<li className="nav-item">
					<a className="nav-link" href="#" onClick={e=>navigate(e, '/code')}><FontAwesomeIcon icon={faCode} /> Code</a>
				</li>
				<li className="nav-item">
					<a className="nav-link" href="#" onClick={e=>navigate(e, '/coffee')}><FontAwesomeIcon icon={faMugHot} /> Coffee</a>
				</li>
			</ul>
		</div>
	</nav>);

}