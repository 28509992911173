/**
 * main.jsx
 * The entry point for the app.
 */

import {StrictMode} from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

import './assets/css/bs-resume.css';

import {App} from '#App';
import config from '#config/server';
import {Home} from '#views/Home';
import {AllPosts} from '#views/AllPosts';
import {NewPost} from '#views/NewPost';
import {EditPost} from '#views/EditPost';
import {Search} from '#views/Search';
import {NotFound} from '#views/NotFound';
import {Post} from '#views/Post';
import {Coffee} from '#views/Coffee';
import {Code} from '#views/Code';
import {Resume} from '#views/Resume';
import {Settings} from '#views/Settings';

(async function main(){
	const rootDiv = document.getElementById('app_container');
	const reactRoot = ReactDOM.createRoot(rootDiv);
	reactRoot.render(<StrictMode>
			<Router basename={config.base_url}>
				<Routes>
					<Route path="/" element={<App />}>
						<Route path="/" element={<Home />} />
						<Route path="/code" element={<Code />} />
						<Route path="/coffee" element={<Coffee />} />
						<Route path="/resume" element={<Resume />} />
						<Route path="/admin" element={<AllPosts />} />
						<Route path="/new_post" element={<NewPost />} />
						<Route path="/post/:slugOrId" element={<Post />} />
						<Route path="/edit_post/:slugOrId" element={<EditPost />} />
						<Route path="/search/:query" element={<Search />} />
						<Route path="/settings" element={<Settings />} />
						<Route path="*" element={<NotFound />}  />
					</Route>
				</Routes>
			</Router>
		</StrictMode>);
})();